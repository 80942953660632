import { useEffect, useState } from 'react';
import Modal from './Modal';
import Image from 'next/image';
import { HeartIcon, StarIcon } from '@heroicons/react/24/outline';
import Button from './Button';
import dayjs from 'dayjs';

export default function PromoModal() {
  const [isOpen, setIsOpen] = useState(false);
  // const router = useRouter();

  useEffect(() => {
    const lsKey = 'PromoModal-lastShowDate';
    const lastShowDate = localStorage.getItem(lsKey);
    if (
      !lastShowDate ||
      dayjs(lastShowDate).isBefore(dayjs().subtract(1, 'day'))
    ) {
      setIsOpen(true);
      localStorage.setItem(lsKey, dayjs().toISOString());
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} className="max-w-2xl">
      <h3 className="text-md font-bold leading-6 mb-6 flex items-center">
        <StarIcon
          height={24}
          className="mr-2 fill-primary-300 text-secondary-500 animate-pulse"
        />{' '}
        Ново
      </h3>

      <Image
        src={require('../public/notification.png')}
        alt="notifications"
        className="float-left p-2 w-24 h-24"
      />
      <p>
        Възползвай се от новата функционалност на MySupermarket и получавай
        известия всеки път, когато някои от твоите любими продукти е с
        промоционална цена във веригата магазини, избрана от теб.
      </p>

      <ul className="p-4 space-y-2 text-lg">
        <li>1. Напиши в търсачката продукта, който търсиш.</li>
        <li>
          2. Запиши търсенето и получавай известия всеки път, когато продукта е
          в промоция с бутона{' '}
          <Button
            size="sm"
            className="mx-1"
            iconLeft={<HeartIcon className="mr-1 h-6" />}
          >
            Запази търсене
          </Button>
        </li>
      </ul>

      <Button variant="outline" onClick={handleClose}>
        OK
      </Button>
    </Modal>
  );
}
